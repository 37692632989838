// import i18n from '@/i18n'
import { mapActions, mapGetters } from 'vuex'
import BreadcrumbComponent from '@/components/commons/breadcrumb/BreadcrumbComponent.vue'
import CurrentStateTable from '@/components/map-report/table/CurrentStateTable.vue'
import i18n from '@/i18n'
import { mapCurrentStateService } from '@/business/mapCurrentStateService'
import service from '@/middleware'
import AutocompleteComponent from '@/components/commons/autocomplete/AutocompleteComponent.vue'
import { clone } from 'ramda'

// constante para actualizar el mapa (en minutos)
const REFRESH_INTERVAL = 0.5

// constante del zoom para centrar el mapa
const CENTER_ZOOM = 18

export default {
  name: 'MapReportComponent',
  components: {
    BreadcrumbComponent,
    CurrentStateTable,
    AutocompleteComponent
  },
  props: {
  },
  data: () => ({
    initPosition: null,
    mapConfiguration: mapCurrentStateService.getInitialMapConfiguration(),
    markers: [],
    tabsCurrentState: [
      {
        id: 'tab-map',
        name: i18n.t('map.map')
      },
      {
        id: 'tab-table',
        name: i18n.t('currentStatusReport.title')
      }
    ],
    selectedTabCurrentState: 'tab-map',
    breadcrumbButtomsCurrentState: [],
    mapButtons: [],
    // instancia del setInterval para actualizar los datos del mapa
    intervalInstance: null,
    // ids de las cosas y número de version de los datos del mapa
    thingIdAndVersionData: [],
    // marcadores que se deben actualizar
    markersToUpdate: [],
    // íconos no encontrados
    notFoundIcons: [],
    // referencias que se deben actualizar
    referencesToUpdate: [],
    middleware: service.http,
    dataPositionAndZoomChanged: {},
    // para activar el overlay con el spinner en la carga inicial del mapa
    loadingMap: true,
    // para centrar el mapa a un marcador en particular
    positionToCenter: {
      lat: null,
      lng: null
    }
  }),
  computed: {
    ...mapGetters([
      'getFirebaseToken'
    ]),
    minutesToRefresh () {
      return this.internalMinutesToRefresh * 60 * 1000
    }
  },
  beforeMount () {
    this.initPosition = mapCurrentStateService.getLastPositionAndZoom()
    if (this.initPosition != null) {
      this.dataPositionAndZoomChanged = clone(this.initPosition)
    }
  },
  created () {
    // TO DO: implementar la actualización del mapa
    this.mapButtons.push({
      id: 'currentStatusUpdateMapButton',
      name: i18n.t('map.refresh'),
      icon: 'refresh',
      function: this.update.bind(this)
    })
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    // actualizar íconos y obtener datos para el mapa
    this.getData()
  },
  beforeDestroy () {
    // limpio la instancia del setInterval de actualización
    clearInterval(this.intervalInstance)
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    /**
     * Para actualizar el mapa
     */
    refreshMap () {
      clearInterval(this.intervalInstance)
      const that = this
      this.intervalInstance = setInterval(
        async function () {
          await that.update()
        }, REFRESH_INTERVAL * 60 * 1000)
    },
    positionAndZoomChanged (data) {
      this.dataPositionAndZoomChanged = {
        ...this.dataPositionAndZoomChanged,
        ...data
      }
      mapCurrentStateService.setLastPositionAndZoom(this.dataPositionAndZoomChanged)
    },
    /**
     * Actualiza los datos que cambiaron del mapa
     */
    async update () {
      // actualizo los íconos que hayan cambiado
      await mapCurrentStateService.getUpdatedIcons()

      // datos actualizados
      const data = await mapCurrentStateService.getUpdatedData(this.thingIdAndVersionData)
      // id de cosa y versión de los datos
      this.thingIdAndVersionData.cleanAndUpdate(data.thingIdAndVersionData)
      // actualizar datos del mapa
      if (data.markers.length > 0) {
        // marcadores que deben actualizarse
        this.markersToUpdate.cleanAndUpdate(data.markers)
        // referencias que deben actualizarse
        this.referencesToUpdate.cleanAndUpdate(data.references)
        // selector
        let marker
        let sort = false
        data.thingMarkers.forEach(thing => {
          marker = this.markers.find(m => m.thingId === thing.thingId)
          if (marker) {
            marker.position.cleanAndUpdate(thing.position)
          } else {
            this.markers.push(thing)
            sort = true
          }
        })
        // si hay que volver a ordenar la lista de cosas del selector
        if (sort) {
          this.markers = this.markers.sort(function sortFunction (a, b) {
            const nameA = a.thingName.toLowerCase()
            const nameB = b.thingName.toLowerCase()
            if (nameA < nameB) {
              return -1
            } else if (nameA > nameB) {
              return 1
            } else {
              return 0
            }
          })
        }
      }
    },
    /**
     * Obtener datos para el mapa
     */
    async getData () {
      this.loadingMap = true
      // limpio el arreglo de marcadores que de tienen que actualizar
      this.markersToUpdate = []
      // actualizo los íconos que hayan cambiado
      // await mapCurrentStateService.getUpdatedIcons()
      mapCurrentStateService.getMapReport().then(response => {
        // mapas
        this.mapConfiguration.cleanAndUpdate(response.maps)
        this.initPosition = clone(this.dataPositionAndZoomChanged)
        // arreglo de cosas para el selector
        response.things.sort(function sortFunction (a, b) {
          const nameA = a.thingName.toLowerCase()
          const nameB = b.thingName.toLowerCase()
          if (nameA < nameB) {
            return -1
          } else if (nameA > nameB) {
            return 1
          } else {
            return 0
          }
        })
        this.markers.cleanAndUpdate(response.things)
        // versiones de los datos
        this.thingIdAndVersionData.cleanAndUpdate(response.thingIdAndVersionData)
        this.loadingMap = false
        this.refreshMap()
      })
    },
    centerMap (selectedItem) {
      if (selectedItem.position != null) {
        this.positionToCenter = {
          lat: selectedItem.position[0],
          lng: selectedItem.position[1],
          zoom: CENTER_ZOOM
        }
      }
    },
    change () {
      const mapRef = this.$refs['MapReportComponent']
      if (mapRef) mapRef.change()
    },
    tabSelectedCurrentState (id) {
      this.selectedTabCurrentState = id
      if (id === 'tab-map') {
        this.change()
      }
    },
    /**
     * Actualizar todos los íconos guardados en la base de datos indexada
     */
    updateSVGIcons (notFound) {
      notFound = notFound.filter(i => i)
      if (notFound != null && Array.isArray(notFound) && notFound.length > 0) {
        const notFoundDifferent = this.getNotFoundIconsDifferent(notFound)
        if (notFoundDifferent.length > 0) {
          console.warn('Icons not found!', notFoundDifferent)
          this.notFoundIcons = [...this.notFoundIcons, ...notFoundDifferent]
          /*
          mapCurrentStateService.getIconsMissed(this.notFoundIcons).then(() => {
            this.getData()
          })
          */
          mapCurrentStateService.getIconsMissed(this.notFoundIcons)
        }
      }
    },
    /**
     * Comparar los íconos no encontrados anteriores con los íconos no encontrados actuales
     * y devolver los valores diferentes
     * @param {*} notfound
     */
    getNotFoundIconsDifferent (notfound) {
      let result = []
      let i = 0
      while (i < notfound.length) {
        if (!this.notFoundIcons.includes(notfound[i])) {
          result.push(notfound[i])
        }
        i++
      }
      return result
    },
    /**
     * Ejecuta una query a la base de datos indexada del browser para obtener un ícono personalizado
     * @param {*} id
     */
    async getCustomIcon (id) {
      const icon = await mapCurrentStateService.getCustomIcon(id)
      return icon != null ? icon : null
    },
    /**
     * Se ejecuta cuando se captura el evento refreshMapData, lanzado cuando no se encuentra un marcador del mapa
     * que se tiene que actualizar
     */
    refreshMapData () {
      // cargar todos los datos de nuevo
      this.getData()
    },
    // Para implementar una vez se guarden las configuraciones de los filtros
    mapResetFilter () {
    }
  }
}
