import { filterRulesMapped } from '@/tools/filterRules'
import mapReportApi from '@/api/mapReport.api'
import { geoReferenceApi, cancelGeoData, cancelPOI } from '@/api/geoReference.api'
import { mapMutations, mapActions } from 'vuex'
import { SnackbarStyle } from '@/constants/constants'
import { dateSortFunction } from '@/tools/functions'
import AddPOIDialogComponent from '@/components/commons/point-of-interest/AddPOIDialog.vue'
import { mapCurrentStateService } from '@/business/mapCurrentStateService'
import i18n from '@/i18n'
import { configurationService } from '../../../business/configurationService'

export default {
  name: 'CurrentStateTable',
  components: {
    AddPOIDialogComponent
  },
  data: () => ({
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: '_id',
    tableComponentButtons: [],
    queryPageSize: 100,
    queryPageNumber: 1,
    pageTotal: undefined,
    loadingTable: true,
    geoDataIndex: -1,
    poiDataIndex: -1,
    maxRadius: 20,
    requestCountGeoData: 0,
    requestCountPOI: 0,
    rowActions: [
      {
        id: 1,
        name: i18n.t('poi.create'),
        color: 'blue',
        icon: 'push_pin'
      }
    ],
    addPOILat: 0,
    addPOILong: 0,
    showAddPOI: false,
    // se activa cuando las referencias fueron resueltas
    referenceResolved: false,
    // se activa cuando las ubicaciones fueron resueltas
    ubicationResolved: false,
    //configuracion de la tabla
    tableConfiguration: {}
  }),
  computed: {
  },
  created () {
    // instancia del timeout para completar los datos de las direcciones
    this.$_debounceTimeoutId = null
    // se agregan los botones para obtener las georeferencias y puntos de interes a la tabla
    this.tableComponentButtons.push(
      {
        id: 'get-geo-data',
        tooltip: i18n.t('poi.loadLocations'),
        icon: 'explore',
        action: this.fireGeoDataRequests.bind(this),
        selectedData: false
      })
    this.tableComponentButtons.push(
      {
        id: 'get-POI',
        tooltip: i18n.t('poi.load'),
        icon: 'push_pin',
        action: this.firePOIRequests.bind(this),
        selectedData: false
      })
    // Si hay query params, los setea para incluirlos en la llamada al backend, de lo contrario, busca todas las reglas de vinculación
    if (this.$route.query) {
      this.queryPageSize = this.$route.query.pageSize ? Number(this.$route.query.pageSize) : 100
      this.queryPageNumber = this.$route.query.pageNumber ? Number(this.$route.query.pageNumber) : 1
    }
    // Valida que los qery params sean correctos y completos
    if ((this.queryPageSize && this.queryPageSize <= 0) || (this.queryPageNumber && this.queryPageNumber <= 0) || (this.queryPageNumber && !this.queryPageSize) || (!this.queryPageNumber && this.queryPageSize)) {
      this.showSnackbar({ visible: true, text: this.$t('error.invalidQueryParams'), timeout: 6000, style: SnackbarStyle.ERROR })
      this.queryPageSize = 100
      this.queryPageNumber = undefined
    }
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
    // Obtener el historial de vinculaciones
    this.getTableConfig();
    this.getData()
  },
  beforeDestroy () {
    // Cancelar las request pendientes
    if (this.requestCountGeoData > 0) {
      cancelGeoData('cancel geo-data requests')
    }
    if (this.requestCountPOI > 0) {
      cancelPOI('cancel poi requests')
    }
    // limpiar el timeout
    clearTimeout(this.$_debounceTimeoutId)
    this.$_debounceTimeoutId = null
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    ...mapMutations('dialog', {
      openDialog: 'openDialog',
      closeDialog: 'closeDialog'
    }),
    /**
     * Resize de los split panel
     */
    resize () {
    },
    /**
     * Click en una fila
     * @param {*} data
     */
    clickRow () {
    },
    /**
     * Refrescar la data
     */
    refreshTable () {
      this.getData(true)
    },
    /**
     * Se ejecuta cuando cambian los datos de la tabla (por búsqueda, filtrado, agregar/quitar columnas, etc.)
     * @param {*} newData
     */
    dataChangeEventHandler () {
    },
    /**
     * TO DO: analizar la implementación del paginado
     */
    async getData (refresh = false) {
      this.loadingTable = true
      mapReportApi.getCurrentStateAll().then(async response => {
        const { data, headers } = await mapCurrentStateService.processReportData(response.data)
        if (!refresh) {
          this.tableHeaders.cleanAndUpdate(headers)
          this.setTableFilterRules()
          this.setSortFunctions()
        }
        this.tableData.cleanAndUpdate(data)
        this.loadingTable = false
        if (this.requestCountGeoData > 0) {
          cancelGeoData('cancel geo-data requests')
        }
        if (this.requestCountPOI > 0) {
          cancelPOI('cancel poi requests')
        }
        this.geoDataIndex = -1
        this.poiDataIndex = -1
        this.maxRadius = 20
        this.requestCountGeoData = 0
        this.requestCountPOI = 0
        // limpiar el timeout
        clearTimeout(this.$_debounceTimeoutId)
        this.$_debounceTimeoutId = null
        // si ya se han resuelto las referencias, vuelvo a ejecutar el proceso
        if (this.referenceResolved) {
          this.firePOIRequests()
        }
        // si ya se han resuelto las ubicaciones, vuelvo a ejecutar el proceso
        if (this.ubicationResolved) {
          this.fireGeoDataRequests()
        }
      })
    },

     /**
     * Para cargar la configuración de la tabla  
     */
     async getTableConfig () {
      const config = await configurationService.get('current-state-MAP-report') || {}
      this.tableConfiguration = config && config.data ? config.data : {}
    },
    /**
     * Para guardar la configuración de la tabla 
     * @param {*} config
     */
    saveTableConfig (config) {
      configurationService.save('current-state-MAP-report', config)
        .then(() => {
          this.showSnackbar({ visible: true, text: i18n.t('user.configuration.saveSuccess'), timeout: 10000, style: SnackbarStyle.SUCCESS })
          this.getTableConfig()
        }).catch(() => {
          this.showSnackbar({ visible: true, text: i18n.t('user.configuration.saveError'), timeout: 10000, style: SnackbarStyle.ERROR })
        })
    },

  
    setTableFilterRules () {
      this.tableFilterRules = {}
      this.tableHeaders.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    notImplemented () {
      this.showSnackbar({ visible: true, text: this.$t('notImplemented'), timeout: 6000, style: SnackbarStyle.WARNING })
    },
    externalPaginator (pageSize, pageNumber) {
      this.queryPageNumber = Number(pageNumber)
      this.queryPageSize = Number(pageSize)
    },
    setSortFunctions () {
      /*
      Para ordenar las columnas fecha y hora
      TO DO: analizar la posibilidad de incluir este tipo de sorting en el componente genérico
      */
      this.tableHeaders.find(header => header.value === 'positionTimestamp').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') }
      this.tableHeaders.find(header => header.value === 'comunicationTimestamp').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') }
    },
    fireGeoDataRequests () {
      const header = this.tableHeaders.find(h => h.value === 'ubication')
      if (header) {
        header.spinner = true
        header.cellConfig = { spinnerColor: '#03A9F4' }
        header.selected = true
      }
      this.getGeoData()
      this.ubicationResolved = true
    },
    firePOIRequests () {
      const header = this.tableHeaders.find(h => h.value === 'reference')
      if (header) {
        header.spinner = true
        header.cellConfig = { spinnerColor: '#03A9F4' }
        header.selected = true
      }
      this.getPOI()
      this.referenceResolved = true
    },
    getGeoData () {
      this.geoDataIndex++
      if (this.geoDataIndex < this.tableData.length) {
        if (!this.tableData[this.geoDataIndex].ubication) {
          this.geoRequest(this.tableData[this.geoDataIndex], this.geoDataIndex)
        } else {
          this.getGeoData()
        }
      }
    },
    getPOI () {
      this.poiDataIndex++
      if (this.poiDataIndex < this.tableData.length) {
        if (!this.tableData[this.poiDataIndex].reference || this.tableData[this.poiDataIndex].reference === '-') {
          this.poiRequest(this.tableData[this.poiDataIndex], this.poiDataIndex)
        } else {
          this.getPOI()
        }
      }
    },
    geoRequest (data, index) {
      this.requestCountGeoData++
      if (this.requestCountGeoData < 10) {
        this.getGeoData()
      }
      geoReferenceApi.getGeoData(data.lat, data.long, this.maxRadius).then(response => {
        this.requestCountGeoData--
        if (this.requestCountGeoData < 10) {
          this.getGeoData()
        }
        const updatedItem = this.tableData[index]
        updatedItem.ubication = response.data.geoLocationData.title + ' - ' + response.data.geoLocationData.subtitle
        this.tableData.splice(index, 1, updatedItem)
      }).catch(() => {
        this.requestCountGeoData--
        if (this.requestCountGeoData < 10) {
          this.getGeoData()
        }
      })
    },
    poiRequest (data, index) {
      this.requestCountPOI++
      if (this.requestCountPOI < 10) {
        this.getPOI()
      }
      geoReferenceApi.getPOI(data.lat, data.long, data.thingId, data.enterpriseId, data.activityId).then(response => {
        this.requestCountPOI--
        if (this.requestCountPOI < 10) {
          this.getPOI()
        }
        const updatedItem = this.tableData[index]
        updatedItem.reference = response.data ? response.data.name : undefined
        this.tableData.splice(index, 1, updatedItem)
      }).catch(() => {
        this.requestCountPOI--
        if (this.requestCountPOI < 10) {
          this.getPOI()
        }
      })
    },
    rowButtonClicked (button, data) {
      switch (button.id) {
        case 1:
          this.addPOILat = parseFloat(data.lat)
          this.addPOILong = parseFloat(data.long)
          this.showAddPOI = true
          break
      }
    },
    closeAddPOIDialog () {
      this.geoDataIndex = -1
      this.getGeoData()
      this.showAddPOI = false
    }
  },
  watch: {
    /*
      [EVALUAR SI CORRESPONDE LA APLICACIÓN DE ESTA VERIFICACIÓN]
      Si hay 10 requests activas, activo un temporizador. Si se cumple el tiempo, entonces cancelo
      las requests activas y continuo buscando.
      Este "reseteo" se emplea para evitar que el proceso se quede congelado en requests de las cuales no se obtiene
      una respuesta.
      Cuando hay menos de 10, el proceso continua sin la activación de temporizadores.
    */
    requestCountGeoData () {
      const that = this
      if (this.requestCountGeoData === 10 && that.geoDataIndex < that.tableData.length) {
        clearTimeout(that.$_debounceTimeoutId)
        that.$_debounceTimeoutId = null
        that.$_debounceTimeoutId = setTimeout(() => {
          cancelGeoData('cancel geo-data requests')
          that.requestCountGeoData = 0
          that.getGeoData()
        }, 60000)
      } else if (that.tableData.filter(a => !a.ubication).length > 0) {
        clearTimeout(that.$_debounceTimeoutId)
        that.$_debounceTimeoutId = null
        that.$_debounceTimeoutId = setTimeout(() => {
          cancelGeoData('cancel geo-data requests')
          that.geoDataIndex = -1
          that.requestCountGeoData = 0
          that.getGeoData()
        }, 60000)
      } else {
        clearTimeout(this.$_debounceTimeoutId)
        this.$_debounceTimeoutId = null
      }
    },
    requestCountPOI () {
      const that = this
      if (this.requestCountPOI === 10 && that.poiDataIndex < that.tableData.length) {
        clearTimeout(that.$_debounceTimeoutId)
        that.$_debounceTimeoutId = null
        that.$_debounceTimeoutId = setTimeout(() => {
          cancelPOI('cancel poi requests')
          that.requestCountPOI = 0
          that.getPOI()
        }, 60000)
      } else if (that.tableData.filter(a => !a.reference).length > 0) {
        clearTimeout(that.$_debounceTimeoutId)
        that.$_debounceTimeoutId = null
        that.$_debounceTimeoutId = setTimeout(() => {
          cancelPOI('cancel poi requests')
          that.poiDataIndex = -1
          that.requestCountPOI = 0
          that.getPOI()
        }, 60000)
      } else {
        clearTimeout(this.$_debounceTimeoutId)
        this.$_debounceTimeoutId = null
      }
    }
  }
}
